import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { FormattedMessage as I18n } from "react-intl"

import Layout from "../components/layout"

export default function StatutoryNotices() {
    const data = useStaticQuery(
        graphql`
            query {
                dataJson {
                    hebergeur
                }
            }
        `
    )

    return (
        <Layout>
            <div style={{ position: "relative", alignItems: "center", padding: "20vh 8vw 15px" }}>
                <h1><I18n id="legal.title" defaultMessage={"blab bla"} /></h1>
                <h2><I18n id="legal.hebergeur" defaultMessage={"bla hébergeur"} /></h2>
                <p>{data.dataJson.hebergeur}</p>
            </div>
        </Layout>
    )
}
